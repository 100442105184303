import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import NoDataFound from '../../../components/NoDataFound';
import PersonalInsights from '../PersonalInsights';
import QuestionnaireCard from './QuestionnaireCard';
import {
  IQuestionnaireDetails,
  QuestionnaireDetailModal,
} from './QuestionnaireDetailsModal';
import {
  useClientAssignments,
  useGetAssignmentResponse,
  usePIQReports,
  useSendAssignmentReminder,
} from '../hooks/UseClientAssignments';
import ClientOverviewCardLoader from '../ClientOverviewCardLoader';
import { getTranslation } from '../../../../../i18n/utilities';
import { getImageUri } from '../../../../../utilities/common/Display';
import { ClientAssignmentStatusMappings } from '../../../../shared/constant/Clients';
import {
  ASSIGNMENTSTATUS,
  AssignmentType,
} from '../../../../shared/constant/Assignment';
import {
  IQuestionnaireDetailsResponse,
  IQuestionnaires,
} from '../types/assignment';
import { IClientAssignment } from '../types/client';
import useTracking from '../../../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../../shared/constant/Analytics';

interface IQuestionnaireListProps {
  clientId: string;
  serviceType: string;
  isClientUnmatched: boolean;
}

interface IQuestionnaireResponseMetaData {
  title: string;
  attemptedOn: string;
  translationKeys: Record<string, Record<string, string>>;
}

export default function QuestionnaireList({
  clientId,
  serviceType,
  isClientUnmatched,
}: Readonly<IQuestionnaireListProps>) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const [piResults, setPIResults] = React.useState(false);
  const [showQuestionnaireDetailModal, setShowTestQuestionnaireModal] =
    React.useState<{
      show: boolean;
      data: IQuestionnaireDetails[];
      metaData: IQuestionnaireResponseMetaData;
    }>({
      show: false,
      data: [] as IQuestionnaireDetails[],
      metaData: {} as IQuestionnaireResponseMetaData,
    });
  const [reminderSentAssignments, setReminderSentAssignments] = React.useState<
    string[]
  >([]);

  const { data: questionnaires, isLoading: isQuestionnairesLoading } =
    useClientAssignments(clientId, AssignmentType.QUESTIONNAIRE);

  const { data: piqReports, isLoading: isPIQReportLoading } =
    usePIQReports(clientId);

  const sendReminderSuccess = (response: string, assignmentId: string) => {
    if (response === 'success') {
      setReminderSentAssignments((prev) => [...prev, assignmentId]);
      toast.success(t('REMINDER_SUCCES_TOAST'));
    }
  };

  const getAssignmentResponseSuccess = (
    response: IQuestionnaireDetailsResponse,
  ) => {
    if (!response?.questions?.length) {
      toast.error('Questionnaire responses not found');
      return;
    }
    setShowTestQuestionnaireModal((prev) => ({
      ...prev,
      show: true,
      data: response?.questions
        ?.filter((item: IQuestionnaires) => Boolean(item.responses.length))
        ?.map((res: IQuestionnaires) => ({
          text: res.question,
          response: res.responses,
          isMCQ: res.responses instanceof Array,
        })),
      metaData: {
        ...prev.metaData,
        attemptedOn: response.submittedAt,
      },
    }));
  };

  const { mutate: sendReminder } = useSendAssignmentReminder(
    sendReminderSuccess,
    clientId,
  );

  const { mutate: getAssignmentResponse } = useGetAssignmentResponse(
    getAssignmentResponseSuccess,
  );

  const handleCtaClick = (data: IClientAssignment) => {
    track(EventNames.clientView, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.questionnaireTab,
      eventLabel:
        data.status === ASSIGNMENTSTATUS.COMPLETED
          ? 'view_results'
          : 'send_reminder',
      eventValue: getTranslation(data?.title, data?.translationKeys),
    });
    if (data.status === ASSIGNMENTSTATUS.COMPLETED) {
      getAssignmentResponse({ clientId, assignmentId: data._id });
      setShowTestQuestionnaireModal((prev) => ({
        ...prev,
        metaData: {
          ...prev.metaData,
          title: data.title,
          translationKeys: data.translationKeys,
        },
      }));
    } else {
      sendReminder(data._id);
    }
  };

  if (isQuestionnairesLoading || isPIQReportLoading) {
    return (
      <section className="grid grid-row-8 gap-4">
        <ClientOverviewCardLoader variant="credits" />
        <ClientOverviewCardLoader variant="credits" />
        <ClientOverviewCardLoader variant="credits" />
        <ClientOverviewCardLoader variant="credits" />
      </section>
    );
  }

  return (
    <section className="flex flex-col gap-3">
      {!piResults && (
        <>
          {!!piqReports && piqReports?.report !== null && (
            <QuestionnaireCard
              isPIQ
              onClick={() => setPIResults(true)}
              isQuestionnaireCompleted
              title={`${getTranslation(
                piqReports.personalInsights?.title,
                piqReports.personalInsights?.translationKeys,
              )} Quiz`}
              mainImage={getImageUri(piqReports.personalInsights?.mainImage)}
              assignmentStatus=""
              assignmentStatusVariant=""
              createdAt=""
            />
          )}
          {questionnaires?.map((item) => (
            <QuestionnaireCard
              key={item._id}
              isQuestionnaireCompleted={
                item?.status === ASSIGNMENTSTATUS.COMPLETED
              }
              onClick={() => handleCtaClick(item)}
              title={getTranslation(item?.title, item?.translationKeys)}
              mainImage={getImageUri(item.mainImage)}
              assignmentStatus={t(
                ClientAssignmentStatusMappings?.[item?.status]?.label,
              )}
              assignmentStatusVariant={
                ClientAssignmentStatusMappings?.[item?.status]?.variant
              }
              createdAt={item?.createdAt}
              isDisabled={(item?.status === ASSIGNMENTSTATUS.ASSIGNED && isClientUnmatched) || reminderSentAssignments.includes(item._id)}
            />
          ))}
          {!questionnaires?.length && !!piqReports && !piqReports?.report && (
            <NoDataFound
              title={t('EMPTY_STATE_QUESTIONNAIRE_HEADER')}
              description={t('EMPTY_STATE_QUESTIONNAIRE_BODY')}
            />
          )}
        </>
      )}
      {piResults && (
        <PersonalInsights
          clientId={clientId}
          serviceType={serviceType}
          onBackClick={() => setPIResults(false)}
        />
      )}

      <QuestionnaireDetailModal
        open={showQuestionnaireDetailModal.show}
        handleClose={() =>
          setShowTestQuestionnaireModal({
            show: false,
            data: [],
            metaData: {} as IQuestionnaireResponseMetaData,
          })
        }
        questionnaireDetails={showQuestionnaireDetailModal.data}
        title={getTranslation(
          showQuestionnaireDetailModal.metaData?.title,
          showQuestionnaireDetailModal.metaData?.translationKeys,
        )}
        attemptedOn={showQuestionnaireDetailModal.metaData?.attemptedOn}
      />
    </section>
  );
}
