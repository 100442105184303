import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Modal from '../../../components/Modal';
import { Button } from '../../../components/Button';
import { getDisplayDate } from '../../../../../utilities/common/Date';
import { getCurrentLocale } from '../../../../../i18n';
import { ClientMatchedOnDateFormat } from '../../../../utilities/common/Date';

export interface IQuestionnaireDetails {
  text: string;
  response: Array<string> | string;
  isMCQ: boolean;
}

interface IQuestionnairedetailModalProps {
  open: boolean;
  handleClose: () => void;
  questionnaireDetails: IQuestionnaireDetails[];
  title: string;
  attemptedOn: string;
}

export function QuestionnaireDetailModal({
  open,
  handleClose,
  questionnaireDetails,
  title,
  attemptedOn,
}: Readonly<IQuestionnairedetailModalProps>) {
  const { t } = useTranslation();
  const currentLocale = getCurrentLocale();
  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={title}
      subtitle={t('ATTEMPTED_ON', {
        date: getDisplayDate({
          locale: currentLocale,
          translatorResource: { ja: 'DISPLAY_DATE_WITHOUT_MENTION' },
          format: { ja: 'MM,DD,YYYY,dddd', en: ClientMatchedOnDateFormat },
          selectedDate: moment(attemptedOn),
          translate: t,
        }),
      })}
      className="w-2/3"
    >
      <section className="w-full box-border">
        <section className="border-solid border border-red mb-[18px]" />
        <section className="grid grid-cols-2 text-sm rounded-lg overflow-hidden items-center">
          <section className="bg-gray-500 font-bold py-2.5 px-4 text-white">
            {t('QUESTIONS')}
          </section>
          <section className="bg-gray-500 font-bold py-2.5 px-4 text-white border-l border-white">
            {t('CLIENT_RESPONSE')}
          </section>
        </section>
          {questionnaireDetails?.map((testQ) => (
            <section key={testQ.text} className='flex odd:bg-gray-50'>
              <section className='w-1/2 py-3 px-5 font-medium border border-t-0 min-h-[4.5rem] border-gray-100 [&:nth-last-child(2)]:rounded-bl-lg'>
              {testQ.text}
              </section>
              <section className='w-1/2 py-3 px-5 font-medium border-r border-b min-h-[4.5rem] border-gray-100 last:rounded-br-lg'>
              {testQ.isMCQ && testQ.response instanceof Array
                ? testQ.response?.map((item) => (
                      <Button
                        key={`${testQ.text}-${item}`} //* since we dnt have any unique id hence using this combination
                        variant="secondary"
                        className="bg-primary-50 text-primary-600 border-primary-100 mb-1"
                      >
                        {item}
                      </Button>
                  ))
                : testQ.response}
              </section>
            </section>
          ))}
      </section>
    </Modal>
  );
}
