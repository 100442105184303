enum EnvType {
  Localhost = 'localhost',
  Staging = 'staging',
  Production = 'production',
}

const EnvironmentVariables: any = {
  [EnvType.Localhost]: {
    baseUrl: 'http://localhost:5005/api/',
    talkyUrl: 'http://localhost:5001/api/',
    irisUrl: 'https://stage.internal.intellect.co/api/iris/',
    contentUrl: 'http://localhost:5000/api/',
    authUrl: 'http://localhost:5003/api/',
    wisdomUrl: 'https://stage.internal.intellect.co/api/wisdom/',
    imageServerUrl: 'https://d3imeoj536zhqj.cloudfront.net/images/',
    noteBookUrl: 'https://stage.internal.intellect.co/api/notebook/',
    websocket: 'https://socket-stage.internal.intellect.co/',
    splashyUrl: 'https://stage.internal.intellect.co/api/splashy/v2/',
    buddyUrl: 'https://stage.internal.intellect.co/api/buddy',
    providerTeamId: 4,
  },
  [EnvType.Staging]: {
    baseUrl: 'https://stage.internal.intellect.co/api/',
    talkyUrl: 'https://stage.internal.intellect.co/api/',
    irisUrl: 'https://stage.internal.intellect.co/api/iris/',
    contentUrl: 'https://stage.internal.intellect.co/api/',
    authUrl: 'https://stage.internal.intellect.co/api/',
    wisdomUrl: 'https://stage.internal.intellect.co/api/wisdom/',
    imageServerUrl: 'https://d3imeoj536zhqj.cloudfront.net/images/',
    noteBookUrl: 'https://stage.internal.intellect.co/api/notebook/',
    websocket: 'https://socket-stage.internal.intellect.co/',
    splashyUrl: 'https://stage.internal.intellect.co/api/splashy/v2/',
    buddyUrl: 'https://stage.internal.intellect.co/api/buddy',
    providerTeamId: 4,
  },
  [EnvType.Production]: {
    baseUrl: 'https://internal.intellect.co/api/',
    talkyUrl: 'https://internal.intellect.co/api/',
    irisUrl: 'https://internal.intellect.co/api/iris/',
    contentUrl: 'https://internal.intellect.co/api/',
    authUrl: 'https://internal.intellect.co/api/',
    wisdomUrl: 'https://internal.intellect.co/api/wisdom/',
    imageServerUrl: 'https://d3imeoj536zhqj.cloudfront.net/images/',
    noteBookUrl: 'https://internal.intellect.co/api/notebook/',
    websocket: 'https://socket.internal.intellect.co/',
    splashyUrl: 'https://internal.intellect.co/api/splashy/v2/',
    buddyUrl: 'https://internal.intellect.co/api/buddy',
    providerTeamId: 89,
  },
};
const { hostname } = window.location;

const Environment =
  EnvironmentVariables[
    hostname.includes('stage') ||
    hostname.includes('localhost') ||
    hostname.includes('127.0.0.1')
      ? EnvType.Staging 
      : EnvType.Production
  ];

const Config = {
  webSocket: `${Environment.websocket}websocket`,
  base: {
    admin: Environment.baseUrl,
    talky: Environment.talkyUrl,
    content: Environment.contentUrl,
  },
  api: {
    auth: {
      login: `${Environment.authUrl}user/auth/login`,
      deleteRequestList: `${Environment.authUrl}user/auth/delete/list`,
      deleteRequestConfirm: `${Environment.authUrl}user/auth/delete/confirm`,
      otpRequest: `${Environment.authUrl}user/auth/password/forgot/request`,
      resetPassword: `${Environment.authUrl}user/auth/password/forgot/reset`,
    },
    profile: {
      getProviderProfile: `${Environment.authUrl}coach/profile/{?}`,
    },
    sessions: {
      allotedSessions: `${Environment.baseUrl}provider/sessions`,
      cancelSession: `${Environment.baseUrl}meeting/{?}/cancel`,
    },
    calendar: {
      list: `${Environment.baseUrl}provider/calendar`,
      unLink: `${Environment.baseUrl}provider/calendar/`,
      link: `${Environment.baseUrl}provider/calendar/sync/new`,
      sync: `${Environment.baseUrl}provider/calendar/sync/`,
    },
    slots: {
      getSlots: `${Environment.baseUrl}provider/slots`,
      getSlotsV2: `${Environment.baseUrl}provider/slots/V2`,
      getHolidays: `${Environment.baseUrl}provider/slots/holiday`,
      getDailyData: `${Environment.baseUrl}provider/slots/dailyoverride?localDate={?}`,
      getDailyDataV2: `${Environment.baseUrl}provider/slots/dailyoverride/v2?localDate={?}`,
      updateDailyOverride: `${Environment.baseUrl}provider/slots/dailyoverride`,
      updateDailyOverrideV2: `${Environment.baseUrl}provider/slots/dailyoverride/v2`,
      getMonthlyOverrides: `${Environment.baseUrl}provider/slots/dailyoverride/calendar`,
      getProviderOpenSlots: `${Environment.baseUrl}provider/{?}/slots`,
    },
    chat: {
      conversations: `${Environment.baseUrl}provider/conversations`,
      conversationsV3: `${Environment.baseUrl}provider/V3/conversations`,
      setChatMessageConsumed: `${Environment.baseUrl}coach/chat/read/{?}`,
    },
    automatedMessages: {
      getTriggers: `${Environment.talkyUrl}chat/messages/triggers`,
      overrideTriggers: `${Environment.talkyUrl}chat/messages/triggers/override`,
      addCustomMessage: `${Environment.talkyUrl}chat/messages/triggers/{?}/messages`,
      listCustomMessages: `${Environment.talkyUrl}chat/messages`,
      activateCustomMessages: `${Environment.talkyUrl}chat/messages/activate`,
    },
    caseNotes: {
      save: `${Environment.baseUrl}provider/caseNote`, // todo can get replaced in future
      questions: `${Environment.baseUrl}provider/caseNote/questions`, // todo can get replaced in future
      answers: `${Environment.baseUrl}provider/caseNote/{?}`, // todo can get replaced in future
      newQuestions: `${Environment.baseUrl}provider/v2/caseNote/questions?form={?}`,
      newIssueOptions: `${Environment.baseUrl}provider/caseNote/questions/{?}`,
      newAnswers: `${Environment.baseUrl}provider/v2/caseNote/{?}`,
      newAnswersInSession: `${Environment.baseUrl}provider/caseNote/{?}/in-session`,
      newSave: `${Environment.baseUrl}provider/v2/caseNote`,
      formType: `${Environment.baseUrl}provider/v2/caseNote/{?}/form`,
      primaryIssueCheck: `${Environment.baseUrl}provider/v2/caseNote/primary/{?}`,
    },
    clients: {
      clientData: `${Environment.baseUrl}provider/client/{?}?page={?}`,
      companies: `${Environment.baseUrl}provider/clients/companies`,
    },
    meeting: {
      getMeetingDetails: `${Environment.baseUrl}meeting/{?}/join/`,
      getSingleSessionDetails: `${Environment.talkyUrl}provider/meetings/{?}`,
    },
    billing: {
      getBillingDetails: `${Environment.baseUrl}provider/billing/{?}`,
      getBillingListing: `${Environment.baseUrl}provider/billing/summary/{?}`,
      getBillingSummary: `${Environment.talkyUrl}provider/billing/summary/v2/{?}`,
    },
    assignments: {
      getAssignments: `${Environment.wisdomUrl}assignments`,
      getAssignmentById: `${Environment.wisdomUrl}assignments/{?}`,
      assignToClients: `${Environment.wisdomUrl}assignments/assign`,
      getAssignmentConfigs: `${Environment.wisdomUrl}assignments/config`,
      getClientAssignments: `${Environment.wisdomUrl}assignments/clients/{?}`,
      sendReminder: `${Environment.wisdomUrl}assignments/{?}/reminders/clients/{?}`,
      getCustomAssignments: `${Environment.wisdomUrl}assignments/custom`,
      createCustomAssignment: `${Environment.wisdomUrl}assignments/custom`,
      getClientReports: `${Environment.wisdomUrl}user/{?}/insights/reports`,
    },
    helpline: {
      getHelplineRecords: `${Environment.noteBookUrl}clients/history?userId={?}`,
      getHelplineRecordDetails: `${Environment.noteBookUrl}sessions/{?}`,
      addHelplineNote: `${Environment.noteBookUrl}sessions/{?}/notes`,
    },
    media: {
      getImage: `${Environment.imageServerUrl}{?}`,
    },
    provider: {
      getproviderClientDetails: `${Environment.baseUrl}provider/client_details/{?}`,
      bookProviderSession: `${Environment.baseUrl}provider/{?}/provider/book`,
      requestCredits: `${Environment.baseUrl}provider/client/message/request-credits`,
      getProviderServices: `${Environment.baseUrl}provider/services`,
      getProviderConfig: `${Environment.talkyUrl}provider/config`,
      updateTimeZone: `${Environment.talkyUrl}provider/{?}`,
      requestIssue: `${Environment.talkyUrl}provider/casenote/entry`,
    },
    iris: {
      getIrisAccessToken: `${Environment.baseUrl}user/auth/serviceToken/iris`,
      getirisConversations: `${Environment.irisUrl}conversations`,
      sendIrisMessage: `${Environment.irisUrl}conversations/{?}/messages`,
      getIrisProviderConversations: `${Environment.irisUrl}conversations/list`,
      deleteIrisMessage: `${Environment.irisUrl}/conversations/:conversationId/messages/{?}`,
      getIrisMessages: `${Environment.irisUrl}conversations/{?}/messages?page={?}`,
    },
    insights: {
      getInsightsDetails: `${Environment.talkyUrl}provider/insights`,
      getInsightAssignmentDetails: `${Environment.wisdomUrl}assignments/provider/insights`,
    },
    analytics: {
      getClientTraits: `${Environment.splashyUrl}analytics/wellbeing/user/{?}/trait`,
      getPHQDetails: `${Environment.splashyUrl}analytics/wellbeing/user/{?}/phq4`,
    },
    cancellationFlows: {
      rescheduleMeeting: `${Environment.talkyUrl}meeting/{?}/v2`,
      noSessionCancellation: `${Environment.talkyUrl}provider/chat/message`,
      getCancellationLatePercentage: `${Environment.talkyUrl}provider/insights/meetings/cancel`,
    },
    sos: {
      track: `${Environment.talkyUrl}provider/sos`,
    },
    outOfOffice: {
      addLeave: `${Environment.talkyUrl}provider/leaves/`,
      removeLeave: `${Environment.talkyUrl}provider/leaves/`,
      activeSession: `${Environment.talkyUrl}provider/leaves/sessions`,
      isOnLeave: `${Environment.talkyUrl}provider/leaves/check`,
    },
    inpersonSession: {
      status: `${Environment.talkyUrl}provider/meetings/{?}/status`,
    },
  },
  apiV2: {
    client: {
      list: `${Environment.buddyUrl}/clients`,
      detail: `${Environment.buddyUrl}/clients/{?}`,
      notes: `${Environment.buddyUrl}/clients/{?}/notes`,
      editNotes: `${Environment.buddyUrl}/clients/{?}/notes/{?}`,
      riskLevels: `${Environment.buddyUrl}/clients/{?}/risk`,
      editRiskLevels: `${Environment.buddyUrl}/clients/{?}/risk/{?}`,
      emergencyContacts: `${Environment.buddyUrl}/clients/{?}/ecp`,
      benefits: `${Environment.baseUrl}user/benefits/{?}`,
    },
    sessions: {
      sessionsWithClient: `${Environment.buddyUrl}/meetings/client/{?}`,
    },
    availability: {
      getMeetings: `${Environment.buddyUrl}/meetings`,
      getAvailability: `${Environment.buddyUrl}/availability`,
      getOverrides: `${Environment.buddyUrl}/availability/overrides`,
      addOverride: `${Environment.buddyUrl}/availability/overrides`,
      getServicesAndClinics: `${Environment.buddyUrl}/availability/services`,
    },
    nps:{
      submissionCheck: `${Environment.buddyUrl}/nps/check-submission`,
      addScore: `${Environment.buddyUrl}/nps`,
    },
    assignments: {
      getClientReports: `${Environment.wisdomUrl}user/{?}/insights/reports`,
      getClientAssignments: `${Environment.wisdomUrl}assignments/clients/{?}`,
      getAssignmentResponse: `${Environment.wisdomUrl}assignments/clients/{?}/assignments/{?}`,
      sendReminder: `${Environment.wisdomUrl}assignments/{?}/reminders/clients/{?}`,
    },
  },
  values: {
    providerTeamId: Environment.providerTeamId,
  },
  provider: {
    ProviderOpenSlots: `${Environment.baseUrl}provider/2014111/slots`,
  },
};

export const externalUrls = {
  providerHandbook: {
    notionLinkEn:
      'https://www.notion.so/intellecthq/Intellect-Provider-Handbook-3d8a06bd067e4969b248b0169468a52f',
    notionLinkJa:
      'https://intellecthq.notion.site/fb5e4bfdb4594f7cbe5f0c02b64e4ba7',
  },
  talentLMSCourse: {
    talentLinkEn: 'https://intellectco.talentlms.com/',
    talentLinkJa: 'https://japan-intellectco.talentlms.com/index',
  },
  referral: {
    typeformLink: 'https://intellecthq.typeform.com/to/VnCSY96D/',
  },
};

export default Config;
