import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import moment from 'moment';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { toast } from 'sonner';
import Typography from '../../components/Typography';
import './CareIntake.scss';
import CaseNoteFormHeader from '../../components/CaseNoteFormHeader';
import { APIDateFormat } from '../../../utilities/common/Date';
import { displayDateFormat } from '../../../utilities/common/Display';
import { ICareIntake } from './types';
import { ICareIntakeFormInputs } from './FormParts/types';
import FormPart1 from './FormParts/FormPart1';
import FormPart2 from './FormParts/FormPart2';
import {
  CareIntakePart,
  CaseNoteFormType,
} from '../../../shared/constant/CoachingCaseNotes';
import {
  createCareIntakeDefaultFormData,
  displayAdditionalSupportEditor,
  handleSaveCaseNoteData,
} from '../CaseNotesV2/utils';
import useUnload from '../../../utilities/hooks/useUnload/useUnload';
import useLayout from '../../../utilities/hooks/useLayout';
import InformationModal from '../InformationModal';
import { ICasenoteQuestionData } from '../CaseNotesV2/types';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
  EventSource,
} from '../../../shared/constant/Analytics';
import useCaseNotesDetails, {
  getAllCaseNoteOptions,
} from '../../../utilities/hooks/useCaseNotesDetails/useCaseNotesDetails';
import Loader from '../../components/Loader';
import useSideDrawer from '../../../utilities/hooks/useSideDrawer/useSideDrawer';
import {
  getClients as _getClients,
  getProviderSessions as _getProviderSessions,
} from '../../../shared/redux/actions/provider';
import Banner from '../../components/Banner';
import useInpersonInfo from '../../../utilities/hooks/useInPersonInfo/useInpersonInfo';
import {
  getInPersonMeetingState,
  getOnsiteMeetingState,
} from '../../../utilities/common/Meeting';
import {
  InPersonBannerStateToMeetingStatusMap,
  InPersonBannerStates,
  InPersonBannerValues,
  OnsiteBannerValues,
  ProviderRole,
} from '../../../shared/constant/Common';
import greenTickIcon from '../../../assets/circularTick.svg';
import decodeCode from '../../../utilities/common/Encryption';
import { MarkNoShowModal } from '../MarkAsNoShowModal/MarkNoShowModal';

function CareIntake({
  clientList,
  isInSessionForm = false,
  formType,
  selectedClientId,
  selectedMeetingId,
  getClients,
  getProviderSessions,
  sessions,
  role,
}: ICareIntake) {
  const { t } = useTranslation();
  const { track, trackDuration } = useTracking();
  const { meetingId, clientId, serviceType } = useParams();

  const [showNoShowWarningModal, setShowNoShowWarningModal] =
    React.useState(false);

  const { updateHeaderSettings, resetNavigation } = useLayout();
  const navigate = useNavigate();
  const { updateSideDrawerSettings, hideHandler } = useSideDrawer();
  const {
    newCaseNoteAnswers,
    newCaseNoteQuestions,
    isQuestionaireLoading,
    isCaseNoteAnswersLoading,
    saveNewcaseNotes,
  } = useCaseNotesDetails({
    meetingId: isInSessionForm
      ? (selectedMeetingId as string)
      : (meetingId as string),
    formType,
  });
  const {
    isOptionsLoading,
    riskCategoriesOptions,
    issuesOptions,
    suicidalAssessmentsOptions,
    recommendationCategoriesOptions,
    sharedFollowUpCategoriesOptions,
  } = getAllCaseNoteOptions(
    newCaseNoteAnswers?.form as string,
    isInSessionForm ? (role as string) : (serviceType as string),
    isInSessionForm
      ? (selectedMeetingId as string)
      : (meetingId as string),
  );

  const selectedClientData = React.useMemo(
    () =>
      clientList?.filter(
        (item) =>
          item.userId ===
          Number(decodeCode(clientId || selectedClientId?.toString() || '')),
      )?.[0],
    [isInSessionForm, clientList],
  );
  const currentSessionData = React.useMemo(
    () => sessions?.find((session) => session.meetingId === meetingId),
    [sessions],
  );

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = useForm<ICareIntakeFormInputs>({
    defaultValues: {},
  });
  useUnload(isDirty);

  React.useEffect(() => {
    if (newCaseNoteAnswers && newCaseNoteQuestions && !isOptionsLoading) {
      const savedFormData =
        createCareIntakeDefaultFormData<ICareIntakeFormInputs>(
          newCaseNoteAnswers,
          newCaseNoteQuestions,
          riskCategoriesOptions,
          recommendationCategoriesOptions,
          sharedFollowUpCategoriesOptions,
          issuesOptions,
        );
      reset(savedFormData);
    }
  }, [newCaseNoteAnswers, newCaseNoteQuestions, isOptionsLoading]);

  React.useEffect(() => {
    if (isInSessionForm) getClients();
  }, [isInSessionForm]);

  const caseNoteQuestionsData = React.useMemo(() => {
    const questions: ICasenoteQuestionData = {};
    newCaseNoteQuestions?.forEach((question: any) => {
      questions[question.type] = {
        question: question.question,
        id: question.id,
      };
    });
    return questions;
  }, [isQuestionaireLoading, newCaseNoteQuestions]);

  const [currentCareIntakePart, setCurrentCareIntakePart] = React.useState(
    CareIntakePart.Part1,
  );
  const [showWarning, setShowWarning] = React.useState<boolean>(false);
  const [inpersonWarning, setInpersonWarning] = React.useState<boolean>(false);

  const watchRiskCategory = watch('riskAssessment');
  const watchCareRecommendations = watch('recommendations');
  const showAdditionalSupportEditor = displayAdditionalSupportEditor(
    watchCareRecommendations,
  );

  const initiateQuestionTracking = () => {
    trackDuration(EventNames.viewIntakeForm);
  };

  const casenoteClickEventTracking = (
    label: string,
    category: string = EventCategories.viewIntakeForm,
  ) => {
    track(EventNames.viewIntakeForm, {
      eventAction: EventActions.click,
      eventCategory: category,
      eventLabel: label,
      featureVersion: 'v1',
    });
  };

  const onSubmit = (data: ICareIntakeFormInputs) => {
    track(EventNames.viewIntakeForm, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.viewIntakeForm,
      eventLabel:
        currentCareIntakePart === CareIntakePart.Part1 ? 'next' : 'save',
      featureVersion: 'v1',
      eventValue: data,
    });
    if (!isInSessionForm && currentCareIntakePart === CareIntakePart.Part1) {
      setCurrentCareIntakePart(CareIntakePart.Part2);
    } else {
      const mtId = isInSessionForm ? selectedMeetingId : meetingId;
      handleSaveCaseNoteData(
        data,
        !!showAdditionalSupportEditor,
        watchRiskCategory,
        mtId as string,
        decodeCode(clientId || (selectedClientId as string)).toString(),
        saveNewcaseNotes,
      );

      reset(data);
    }
  };
  const onConfirmClick = () => {
    casenoteClickEventTracking('reminder');
    if (isInSessionForm) {
      setShowWarning(false);
      hideHandler?.();
    } else {
      navigate(-1);
    }
    setShowWarning(false);
  };

  React.useEffect(() => {
    // for instrumentation only
    if (
      !(isQuestionaireLoading || isCaseNoteAnswersLoading || isOptionsLoading)
    ) {
      track(EventNames.viewIntakeForm, {
        eventAction: EventActions.load,
        eventCategory: EventCategories.viewIntakeForm,
        eventLabel: 'view_intake_form',
        featureVersion: 'v1',
      });
    }
  }, [isQuestionaireLoading, isCaseNoteAnswersLoading, isOptionsLoading]);

  React.useEffect(() => {
    const onBack = () =>
      currentCareIntakePart === CareIntakePart.Part2
        ? setCurrentCareIntakePart(CareIntakePart.Part1)
        : setShowWarning(true);
    if (isDirty || currentCareIntakePart === CareIntakePart.Part2) {
      updateHeaderSettings({
        onBackButtonClick: () => {
          onBack();
        },
      });
    }
    return () => {
      resetNavigation();
    };
  }, [isDirty, currentCareIntakePart]);

  React.useEffect(() => {
    if (isEmpty(sessions)) {
      getProviderSessions();
    }
  }, []);

  React.useEffect(() => {
    if (isInSessionForm) {
      updateSideDrawerSettings({
        beforeHideHandler: () => {
          console.log('updated');
          setShowWarning(isDirty);
          return isDirty;
        },
      });
    }
  }, [isDirty, isInSessionForm]);

  const displayDateVisible = React.useMemo(() => {
    if (!isInSessionForm) {
      return newCaseNoteAnswers?.meeting?.date
        ? moment(newCaseNoteAnswers?.meeting?.date, APIDateFormat).format(
            displayDateFormat,
          )
        : t('noSessions');
    }
    return '';
  }, [isInSessionForm, newCaseNoteAnswers]);

  const onUpdateInPersonStatusSuccessFromCasenote = (
    successData: { success: { providerRole: string } },
    request: {
      meetingStatus: string;
      meetingId: string;
    },
  ) => {
    if (
      request.meetingStatus === InPersonBannerStateToMeetingStatusMap.noShow
    ) {
      toast.success(
        successData.success.providerRole === ProviderRole.ONSITE
          ? t('NO_SHOW_SUCCESS_PROMPT')
          : t('NO_SHOW_SUCCESS'),
      );
    }
    getProviderSessions();
  };

  const {
    inPersonStatusUpdated,
    singleSessionData,
    updateInPersonStatus,
    singleSessionDataLoading,
  } = useInpersonInfo(
    meetingId as string,
    !isInSessionForm,
    onUpdateInPersonStatusSuccessFromCasenote,
  );

  const inPersonBannerState = React.useMemo(
    () =>
      getInPersonMeetingState({
        singleSessionData,
        enable: [CaseNoteFormType.V3, CaseNoteFormType.V4].includes(formType),
      }),
    [formType, singleSessionData],
  );
  const onsiteBannerState = React.useMemo(
    () =>
      getOnsiteMeetingState(singleSessionData, {
        isAttemptingNoShow: showNoShowWarningModal,
      }),
    [singleSessionData, showNoShowWarningModal],
  );

  const onInPersonBannerBtnClick = (bannerState: InPersonBannerStates) => {
    if (currentSessionData?.providerRole === ProviderRole.CAREOFFSITE) {
      track(EventNames.inPersonSession, {
        eventAction: EventActions.click,
        eventCategory: bannerState,
        eventLabel: bannerState,
        featureVersion: 'v1',
        eventSource: EventSource.caseNotes,
      });
    }
    if (
      ![
        InPersonBannerStates.start,
        InPersonBannerStates.noShow,
        InPersonBannerStates.stop,
      ].includes(bannerState)
    ) {
      return;
    }

    if (bannerState === InPersonBannerStates.stop) {
      if (currentSessionData?.providerRole === ProviderRole.ONSITE) {
        track(EventNames.onsiteSession, {
          eventAction: EventActions.click,
          eventCategory: EventCategories.completeSession,
          eventLabel: 'complete_session',
          eventSource: 'case_notes',
        });
      }
      setInpersonWarning(true);
      return;
    }
    if (
      bannerState === InPersonBannerStates.start &&
      currentSessionData?.providerRole === ProviderRole.ONSITE
    ) {
      track(EventNames.onsiteSession, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.beginSession,
        eventLabel: 'begin_session',
        eventSource: 'case_notes',
      });
    }
    updateInPersonStatus({
      meetingStatus: InPersonBannerStateToMeetingStatusMap[bannerState],
      meetingId: meetingId as string,
    });
  };
  const onInPersonBannerSecondaryBtnClick = (
    bannerState: InPersonBannerStates,
  ) => {
    if (bannerState === InPersonBannerStates.start) {
      track(EventNames.onsiteSession, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.markNoShow,
        eventLabel: 'mark_no_show',
        eventSource: 'case_notes',
      });
      setShowNoShowWarningModal(true);
    }
  };

  if (
    isQuestionaireLoading ||
    isCaseNoteAnswersLoading ||
    isOptionsLoading ||
    singleSessionDataLoading ||
    inPersonStatusUpdated
  ) {
    return <Loader withBackdrop={false} useFullPage />;
  }

  return (
    <>
      {currentSessionData?.providerRole === ProviderRole.ONSITE &&
      onsiteBannerState !== InPersonBannerStates.unknown ? (
        <Banner
          primaryContent={t(
            OnsiteBannerValues[onsiteBannerState].bannerContent,
          )}
          onClick={() => onInPersonBannerBtnClick(onsiteBannerState)}
          buttonLabel={t(OnsiteBannerValues[onsiteBannerState].btnLabel)}
          bannerVariant="inperson"
          withBgColor={OnsiteBannerValues[onsiteBannerState].bannerColor}
          buttonVariant={OnsiteBannerValues[onsiteBannerState].btnVariant}
          width={OnsiteBannerValues[onsiteBannerState].btnWidth}
          height="52px"
          backgroundColor={OnsiteBannerValues[onsiteBannerState].btnColor}
          fontSize={16}
          primaryContentColor=""
          displayBannerCTA={OnsiteBannerValues[onsiteBannerState].displayBtn}
          contentWidth="100%"
          btnIcon={greenTickIcon}
          secondaryCTA={{
            ...OnsiteBannerValues[onsiteBannerState].secondaryCTA,
            onClick: () => onInPersonBannerSecondaryBtnClick(onsiteBannerState),
          }}
        />
      ) : (
        inPersonBannerState !== InPersonBannerStates.unknown && (
          <Banner
            primaryContent={t(
              InPersonBannerValues[inPersonBannerState].bannerContent,
            )}
            onClick={() => onInPersonBannerBtnClick(inPersonBannerState)}
            buttonLabel={t(InPersonBannerValues[inPersonBannerState].btnLabel)}
            bannerVariant="inperson"
            withBgColor={InPersonBannerValues[inPersonBannerState].bannerColor}
            buttonVariant={InPersonBannerValues[inPersonBannerState].btnVariant}
            width={InPersonBannerValues[inPersonBannerState].btnWidth}
            height="52px"
            backgroundColor={InPersonBannerValues[inPersonBannerState].btnColor}
            fontSize={16}
            primaryContentColor=""
            displayBannerCTA={
              InPersonBannerValues[inPersonBannerState].displayBtn
            }
            contentWidth="100%"
            btnIcon={greenTickIcon}
          />
        )
      )}
      <article
        className={`care-intake-wrapper ${
          isInSessionForm && 'care-intake-insession'
        }`}
      >
        <section className="header-wrapper">
          {!isInSessionForm && (
            <Typography size={16} withColor="#B9B9B9" weight="600">
              {t('INTAKE_PART', { count: currentCareIntakePart })}
            </Typography>
          )}
          <CaseNoteFormHeader
            headerText={
              isInSessionForm
                ? t('SESSION', {
                    count: newCaseNoteAnswers?.meeting?.count,
                  })
                : t('INTAKE_FORM', {
                    count: newCaseNoteAnswers?.meeting?.count,
                  })
            }
            diplayDate={displayDateVisible}
          />
        </section>
        <section className="body-wrapper">
          <form onSubmit={handleSubmit(onSubmit)}>
            {isInSessionForm ||
            currentCareIntakePart === CareIntakePart.Part1 ? (
              <FormPart1
                control={control}
                caseNoteQuestionsData={caseNoteQuestionsData}
                riskCategoriesOptions={riskCategoriesOptions}
                issuesOptions={issuesOptions}
                suicidalAssessmentsOptions={suicidalAssessmentsOptions}
                watchRiskCategory={watchRiskCategory}
                startQuestionDurationTracking={initiateQuestionTracking}
                caseNoteEventTracking={casenoteClickEventTracking}
                showTags={!isInSessionForm}
                selectedClientData={selectedClientData}
                meetingId={selectedMeetingId || meetingId}
              />
            ) : (
              <FormPart2
                control={control}
                caseNoteQuestionsData={caseNoteQuestionsData}
                newCaseNoteAnswers={newCaseNoteAnswers}
                watchCareRecommendations={watchCareRecommendations}
                showAdditionalSupportEditor={!!showAdditionalSupportEditor}
                recommendationCategoriesOptions={
                  recommendationCategoriesOptions
                }
                sharedFollowUpCategoriesOptions={
                  sharedFollowUpCategoriesOptions
                }
                startQuestionDurationTracking={initiateQuestionTracking}
                caseNoteEventTracking={casenoteClickEventTracking}
              />
            )}
            <InformationModal
              headerText={t('IMPORTANT')}
              bodyText={t('WARNING_DESC')}
              onClose={() => setShowWarning(false)}
              show={showWarning}
              rightBtnLabel={t('confirmButton')}
              leftBtnLabel={t('CANCEL_CTA')}
              customBtnContainerStyle="custom-style"
              borderRadius="100px"
              leftBtnVariant="secondary"
              fontSize={14}
              fontWeight="600"
              width="221px"
              height="45px"
              headerFontSize={24}
              contentFontSize={16}
              leftBtnClick={() => {
                setShowWarning(false);
                casenoteClickEventTracking('cancel');
              }}
              rightBtnClick={onConfirmClick}
            />
            <InformationModal
              headerText={
                currentSessionData?.providerRole === ProviderRole.ONSITE
                  ? t('END_SESSION_POPUP_TITLE')
                  : t('IS_SESSION_OVER')
              }
              bodyText={
                currentSessionData?.providerRole === ProviderRole.ONSITE
                  ? t('END_SESSION_POPUP_BODY_1')
                  : t('IN_PERSON_SUBTEXT')
              }
              onClose={() => setInpersonWarning(false)}
              show={inpersonWarning}
              rightBtnLabel={
                currentSessionData?.providerRole === ProviderRole.ONSITE
                  ? t('END_SESSION_POPUP_CTA')
                  : t('MARK_SESSION_COMPLETE')
              }
              leftBtnLabel=""
              customBtnContainerStyle="custom-style"
              borderRadius="100px"
              fontSize={14}
              fontWeight="600"
              width="100%"
              height="45px"
              headerFontSize={24}
              contentFontSize={16}
              modalVariant="single-cta"
              noteText={
                currentSessionData?.providerRole === ProviderRole.ONSITE
                  ? t('END_SESSION_POPUP_BODY_2')
                  : t('IN_PERSON_NOTE')
              }
              rightBtnClick={() => {
                if (currentSessionData?.providerRole === ProviderRole.ONSITE) {
                  track(EventNames.onsiteSession, {
                    eventAction: EventActions.click,
                    eventCategory: EventCategories.completeSessionConfirm,
                    eventLabel: 'complete_session_confirm',
                    eventSource: 'case_notes',
                  });
                } else {
                  track(EventNames.inPersonSession, {
                    eventAction: EventActions.click,
                    eventCategory: EventCategories.endSessionConfirm,
                    eventLabel: 'end_session_confirm',
                    featureVersion: 'v1',
                    eventSource: EventSource.caseNotes,
                    eventValue: moment
                      .unix(Number(singleSessionData?.scheduledStartTime))
                      .diff(moment()),
                  });
                }
                updateInPersonStatus({
                  meetingStatus: InPersonBannerStateToMeetingStatusMap.stop,
                  meetingId: meetingId as string,
                });
                setInpersonWarning(false);
              }}
            />

            <motion.input
              whileHover={{ scale: 1.025 }}
              whileTap={{ scale: 0.975 }}
              className="care-form-button"
              value={
                isInSessionForm ||
                currentCareIntakePart === CareIntakePart.Part2
                  ? t('SAVE_CTA')
                  : t('NEXT')
              }
              type="submit"
            />
          </form>
        </section>
      </article>

      {showNoShowWarningModal ? (
        <MarkNoShowModal
          open={showNoShowWarningModal}
          handleClose={() => setShowNoShowWarningModal(false)}
          handleSubmit={() => {
            track(EventNames.onsiteSession, {
              eventAction: EventActions.click,
              eventCategory: EventCategories.markNoShowConfirm,
              eventLabel: 'mark_no_show_confirm',
              featureVersion: 'v1',
              eventSource: 'case_notes',
            });
            onInPersonBannerBtnClick(onsiteBannerState);
            setShowNoShowWarningModal(false);
          }}
        />
      ) : null}
    </>
  );
}
const mapStateToProps = (state: any) => ({
  clientList: state.provider.clients,
  sessions: state.provider.sessions,
});
const mapDispatchToProps = (dispatch: Function) => ({
  getClients: () => dispatch(_getClients()),
  getProviderSessions: (month: string) => dispatch(_getProviderSessions(month)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CareIntake);
// export default CareIntake;
