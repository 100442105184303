import Config from '../../../../shared/Config';
import NetworkClient from '../../../../shared/service/Network';
import { beautifyUrl } from '../../../../utilities/common/Path';

const client = new NetworkClient();

const errorMessages = {
  generic: 'Unable to fetch client details, please try again!',
};

export const getAssignmentResponses = async (
  clientId: string,
  assignmentId: string,
) => {
  const response = await client.doGet(
    beautifyUrl(Config.apiV2.assignments.getAssignmentResponse, [
      clientId,
      assignmentId,
    ]),
  );
  if (response?.data) {
    return response?.data;
  }

  throw new Error(errorMessages.generic);
};

export const getPIQReports = async (clientId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.apiV2.assignments.getClientReports, [clientId]),
  );
  if (response?.success) {
    return response?.data;
  }
  throw new Error(response.error?.message);
};
