/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { motion } from 'framer-motion';
import moment from 'moment';
import './CaseNote.scss';
import { ICasenoteQuestionData, ICaseNoteFormInputs } from './types';
import alertCircleIcon from '../../../assets/alert-circle.svg';
import RadioGroup from '../../components/RadioGroup';
import {
  CaseNoteQuestionTypes,
  Recommendations,
  RiskAssesment,
} from '../../../shared/constant/CoachingCaseNotes';
import SelectBoxV2 from '../../components/SelectBoxV2';
import CareReferral from '../CareReferral';
import { externalUrls } from '../../../shared/Config';
import TextEditor from '../../components/TextEditor';
import CheckboxGroup from '../../components/CheckboxGroup';
import useCaseNotesDetails, {
  getAllCaseNoteOptions,
} from '../../../utilities/hooks/useCaseNotesDetails/useCaseNotesDetails';
import Loader from '../../components/Loader';
import Tags from '../Tags';
import {
  createCoachingDefaultFormData,
  displayAdditionalSupportEditor,
  handleSaveCaseNoteData,
  showSucidalAssessmentEditors,
} from './utils';
import { APIDateFormat } from '../../../utilities/common/Date';
import { displayDateFormat } from '../../../utilities/common/Display';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
  EventSource,
} from '../../../shared/constant/Analytics';
import CaseNoteFormHeader from '../../components/CaseNoteFormHeader';
import useUnload from '../../../utilities/hooks/useUnload/useUnload';
import InformationModal from '../InformationModal';
import useLayout from '../../../utilities/hooks/useLayout';
import RiskAndIssuePresenter from '../RiskAndIssuePresenter';
import arrowIcon from '../../../assets/slanting arrow.svg';
import decodeCode from '../../../utilities/common/Encryption';

function CaseNote() {
  const { meetingId, clientId, serviceType } = useParams();
  const { t } = useTranslation();
  const { track, trackDuration } = useTracking();
  const { updateHeaderSettings, resetNavigation } = useLayout();
  const navigate = useNavigate();

  const {
    newCaseNoteQuestions,
    newCaseNoteAnswers,
    isQuestionaireLoading,
    isCaseNoteAnswersLoading,
    saveNewcaseNotes,
  } = useCaseNotesDetails({ meetingId: meetingId as string });

  const {
    isOptionsLoading,
    riskCategoriesOptions,
    techniqueCategoriesOptions,
    progressCategoriesOptions,
    recommendationCategoriesOptions,
    sharedFollowUpCategoriesOptions,
    issuesOptions,
    suicidalAssessmentsOptions,
  } = getAllCaseNoteOptions(
    newCaseNoteAnswers?.form as string,
    serviceType as string,
    meetingId as string,
  );

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = useForm<ICaseNoteFormInputs>({
    defaultValues: {},
  });
  useUnload(isDirty);

  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const [showWarning, setShowWarning] = React.useState<boolean>(false);

  const caseNoteQuestionsData = React.useMemo(() => {
    const questions: ICasenoteQuestionData = {};
    newCaseNoteQuestions?.forEach((question: any) => {
      questions[question.type] = {
        question: question.question,
        id: question.id,
      };
    });
    return questions;
  }, [isQuestionaireLoading, newCaseNoteQuestions]);

  const watchRiskCategory = watch('riskAssessment');
  const watchRecommendations = watch('recommendations');

  const showCareReferral = React.useMemo(
    () =>
      [
        RiskAssesment.MediumRisk.toString(),
        RiskAssesment.HighRiskNonCritical.toString(),
        RiskAssesment.HighRiskCritical.toString(),
      ].includes(watchRiskCategory?.value?.toString()),
    [watchRiskCategory],
  );

  const showSucidalAssessmentEditor =
    showSucidalAssessmentEditors(watchRiskCategory);
  const showAdditionalSupportEditor =
    displayAdditionalSupportEditor(watchRecommendations);

  const handleInformIntellect = () => {
    if (
      !(
        watchRiskCategory.value.toString() ===
        RiskAssesment.HighRiskCritical.toString()
      )
    ) {
      window.open(externalUrls.referral.typeformLink, '_blank', 'noreferrer');
      track(EventNames.viewFullSessionCaseNotes, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.viewFullSessionCaseNotes,
        eventLabel: 'refer_client',
        featureVersion: 'v1',
        eventValue: 'non-critical',
        eventSource: EventSource.caseNoteAssessment,
      });
    } else {
      setIsVisible(true);
    }
  };

  const initiateQuestionTracking = () => {
    trackDuration(EventNames.viewFullSessionCaseNotes);
  };

  const casenoteClickEventTracking = (
    label: string,
    category: string = EventCategories.viewFullSessionCaseNotes,
  ) => {
    track(EventNames.viewFullSessionCaseNotes, {
      eventAction: EventActions.click,
      eventCategory: category,
      eventLabel: label,
      featureVersion: 'v1',
      eventValue: 'coaching',
    });
  };

  const handleSaveCaseNotes = (data: ICaseNoteFormInputs) => {
    track(EventNames.viewFullSessionCaseNotes, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.viewFullSessionCaseNotes,
      eventLabel: 'save',
      featureVersion: 'v1',
      eventValue: data,
    });
    handleSaveCaseNoteData(
      data,
      !!showAdditionalSupportEditor,
      watchRiskCategory,
      meetingId as string,
      decodeCode(clientId as string).toString(),
      saveNewcaseNotes,
    );
    reset(data);
  };

  React.useEffect(() => {
    // for instrumentation only
    if (
      !(isQuestionaireLoading || isCaseNoteAnswersLoading || isOptionsLoading)
    ) {
      track(EventNames.viewFullSessionCaseNotes, {
        eventAction: EventActions.load,
        eventCategory: EventCategories.viewFullSessionCaseNotes,
        eventLabel: 'view_full_session_case_notes',
        featureVersion: 'v1',
        eventValue: 'coaching',
      });
    }
  }, [isQuestionaireLoading, isCaseNoteAnswersLoading, isOptionsLoading]);

  React.useEffect(() => {
    if (newCaseNoteAnswers && newCaseNoteQuestions && !isOptionsLoading) {
      const savedFormData = createCoachingDefaultFormData<ICaseNoteFormInputs>(
        newCaseNoteAnswers,
        newCaseNoteQuestions,
        riskCategoriesOptions,
        techniqueCategoriesOptions,
        progressCategoriesOptions,
        recommendationCategoriesOptions,
        sharedFollowUpCategoriesOptions,
        issuesOptions,
      );
      reset(savedFormData);
    }
  }, [newCaseNoteAnswers, newCaseNoteQuestions, isOptionsLoading]);

  React.useEffect(() => {
    if (isDirty) {
      updateHeaderSettings({
        onBackButtonClick: () => {
          setShowWarning(true);
        },
      });
    }

    return () => {
      resetNavigation();
    };
  }, [isDirty]);

  if (isQuestionaireLoading || isCaseNoteAnswersLoading || isOptionsLoading) {
    return <Loader withBackdrop={false} useFullPage />;
  }

  return (
    <article className="case-note-wrapper">
      <CaseNoteFormHeader
        headerText={t('SESSION', {
          count: newCaseNoteAnswers?.meeting?.count,
        })}
        diplayDate={
          newCaseNoteAnswers?.meeting?.date
            ? moment(newCaseNoteAnswers?.meeting?.date, APIDateFormat).format(
                displayDateFormat,
              )
            : t('noSessions')
        }
      />
      <section className="case-note-body">
        <form onSubmit={handleSubmit(handleSaveCaseNotes)}>
          <RiskAndIssuePresenter
            meetingId={meetingId as string}
            control={control}
            caseNoteQuestionsData={caseNoteQuestionsData}
            riskCategoriesOptions={riskCategoriesOptions}
            issuesOptions={issuesOptions}
            suicidalAssessmentsOptions={suicidalAssessmentsOptions}
            showSucidalAssessmentEditor={showSucidalAssessmentEditor}
            showCareReferral={showCareReferral}
            handleInformBtnClick={handleInformIntellect}
            showInfoModal={isVisible}
            infoModalOnClose={() => setIsVisible(false)}
            startQuestionDurationTracking={initiateQuestionTracking}
            caseNoteEventTracking={casenoteClickEventTracking}
          />
          <section className="session-note-wrapper">
            <label className="form-label" htmlFor="sessionNotes">
              {
                caseNoteQuestionsData[CaseNoteQuestionTypes.SessionNotes]
                  ?.question
              }
            </label>
            <Controller
              name="sessionNotes"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <section className="editor-wrapper">
                  <TextEditor
                    {...field}
                    onTextValueChange={(note) =>
                      field.onChange({
                        id: note,
                        questionId:
                          caseNoteQuestionsData[
                            CaseNoteQuestionTypes.SessionNotes
                          ]?.id,
                      })
                    }
                    defaultTemplate={field.value?.id || ''}
                    parametersValue={[]}
                    maxCharacterLimit={5000}
                    showMaxCharText={false}
                    placeHolderText={t('SESSION_NOTE_PLACEHOLDER')}
                    className="custom-editor-style"
                    onFocus={initiateQuestionTracking}
                    onBlur={() =>
                      casenoteClickEventTracking(
                        caseNoteQuestionsData[
                          CaseNoteQuestionTypes.SessionNotes
                        ]?.id?.toString(),
                        EventCategories.questionId,
                      )
                    }
                  />
                </section>
              )}
            />
          </section>
          <section className="technique-wrapper">
            <section className="header-info-container extra-padding">
              <label className="form-label" htmlFor="interventionsAndModality">
                {
                  caseNoteQuestionsData[
                    CaseNoteQuestionTypes.InterventionsAndModality
                  ]?.question
                }
              </label>
              <Tags
                variant="disclaimer"
                tags={t('SHARED_DISCLAIMER')}
                icon={alertCircleIcon}
                uniqueKey="casenote_disclaimer"
                toolTipText={t('DISCLAIMER_TEXT')}
                toolTipClass="customTooltip"
              />
            </section>
            <Controller
              name="interventionsAndModality"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <SelectBoxV2
                  {...field}
                  values={techniqueCategoriesOptions}
                  selectedValue={field?.value?.value}
                  onChange={(val) => {
                    field.onChange({
                      ...techniqueCategoriesOptions.find(
                        ({ value }) => value === val,
                      ),
                      questionId:
                        caseNoteQuestionsData[
                          CaseNoteQuestionTypes.InterventionsAndModality
                        ]?.id,
                    });
                    casenoteClickEventTracking(
                      caseNoteQuestionsData[
                        CaseNoteQuestionTypes.InterventionsAndModality
                      ]?.id?.toString(),
                      EventCategories.questionId,
                    );
                  }}
                  fontSize={14}
                  placeHolderText={t('SELECT')}
                />
              )}
            />
          </section>
          <section className="progress-wrapper">
            <section className="header-info-container">
              <label className="form-label" htmlFor="progressTowardsGoals">
                {
                  caseNoteQuestionsData[
                    CaseNoteQuestionTypes.ProgressTowardsGoals
                  ]?.question
                }
              </label>
              <Tags
                variant="disclaimer"
                tags={t('SHARED_DISCLAIMER')}
                icon={alertCircleIcon}
                uniqueKey="casenote_disclaimer"
                toolTipText={t('DISCLAIMER_TEXT')}
                toolTipClass="customTooltip"
              />
            </section>
            <Controller
              name="progressTowardsGoals"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <RadioGroup
                  {...field}
                  name="progress-status"
                  values={progressCategoriesOptions}
                  onChange={(_, selected) => {
                    field.onChange({
                      ...selected,
                      questionId:
                        caseNoteQuestionsData[
                          CaseNoteQuestionTypes.ProgressTowardsGoals
                        ]?.id,
                    });
                    casenoteClickEventTracking(
                      caseNoteQuestionsData[
                        CaseNoteQuestionTypes.ProgressTowardsGoals
                      ]?.id?.toString(),
                      EventCategories.questionId,
                    );
                  }}
                  selectedValue={field?.value?.value}
                  customCss
                />
              )}
            />
          </section>
          <section className="recommendation-wrapper">
            <section className="header-info-container">
              <label className="form-label" htmlFor="recommendations">
                {
                  caseNoteQuestionsData[CaseNoteQuestionTypes.Recommendations]
                    ?.question
                }
              </label>
              <Tags
                variant="disclaimer"
                tags={t('SHARED_DISCLAIMER')}
                icon={alertCircleIcon}
                uniqueKey="casenote_disclaimer"
                toolTipText={t('DISCLAIMER_TEXT')}
                toolTipClass="customTooltip"
              />
            </section>
            <Controller
              name="recommendations"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <CheckboxGroup
                  {...field}
                  options={recommendationCategoriesOptions}
                  defaultSelected={field?.value?.id}
                  onChange={(selected) => {
                    field.onChange({
                      id: selected,
                      questionId:
                        caseNoteQuestionsData[
                          CaseNoteQuestionTypes.Recommendations
                        ]?.id,
                    });
                    casenoteClickEventTracking(
                      caseNoteQuestionsData[
                        CaseNoteQuestionTypes.Recommendations
                      ]?.id?.toString(),
                      EventCategories.questionId,
                    );
                  }}
                />
              )}
            />
            {watchRecommendations?.id.find(
              (item) => item.value === Recommendations.Referral,
            ) && (
              <CareReferral
                desc={t('REFERRAL_INSTRUCTION')}
                icon={alertCircleIcon}
                iconHeight="16px"
                iconWidth="16px"
                fontSize={14}
                fontWeight="700"
                onClick={() =>
                  window.open(
                    externalUrls.referral.typeformLink,
                    '_blank',
                    'noreferrer',
                  )
                }
                btnLabel={t('REFERRAL_CTA')}
                btnLabelIcon={arrowIcon}
              />
            )}
            {showAdditionalSupportEditor && (
              <Controller
                name="additionalSupport"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <section className="editor-wrapper">
                    <TextEditor
                      {...field}
                      onTextValueChange={field.onChange}
                      defaultTemplate={field.value || ''}
                      parametersValue={[]}
                      maxCharacterLimit={5000}
                      showMaxCharText={false}
                      placeHolderText={t('RECOMMENDATION_PLACEHOLDER')}
                      className="custom-editor-style"
                      onFocus={initiateQuestionTracking}
                      onBlur={() =>
                        casenoteClickEventTracking(
                          'additional_support',
                          EventCategories.questionId,
                        )
                      }
                    />
                  </section>
                )}
              />
            )}
          </section>
          <section className="followup-share-wrapper">
            <section className="shared-followup">
              <label
                className="form-label followup"
                htmlFor="sharedFollowUpPlan"
              >
                {
                  caseNoteQuestionsData[
                    CaseNoteQuestionTypes.SharedFollowUpPlan
                  ]?.question
                }
              </label>
              <Controller
                name="sharedFollowUpPlan"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <RadioGroup
                    {...field}
                    name="shared-follow-up"
                    values={sharedFollowUpCategoriesOptions}
                    onChange={(_, selected) => {
                      field.onChange({
                        ...selected,
                        questionId:
                          caseNoteQuestionsData[
                            CaseNoteQuestionTypes.SharedFollowUpPlan
                          ]?.id,
                      });
                      casenoteClickEventTracking(
                        caseNoteQuestionsData[
                          CaseNoteQuestionTypes.SharedFollowUpPlan
                        ]?.id?.toString(),
                        EventCategories.questionId,
                      );
                    }}
                    selectedValue={field.value?.value}
                    customCss
                  />
                )}
              />
            </section>
          </section>
          <section className="followup-wrapper">
            <label className="form-label" htmlFor="followUpPlan">
              {
                caseNoteQuestionsData[CaseNoteQuestionTypes.FollowUpPlan]
                  ?.question
              }
            </label>
            <Controller
              name="followUpPlan"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <section className="editor-wrapper">
                  <TextEditor
                    {...field}
                    onTextValueChange={(note) =>
                      field.onChange({
                        id: note,
                        questionId:
                          caseNoteQuestionsData[
                            CaseNoteQuestionTypes.FollowUpPlan
                          ]?.id,
                      })
                    }
                    defaultTemplate={field.value?.id || ''}
                    parametersValue={[]}
                    maxCharacterLimit={5000}
                    showMaxCharText={false}
                    placeHolderText={t('FOLLOW_UP_PLACEHOLDER')}
                    className="custom-editor-style"
                    onFocus={initiateQuestionTracking}
                    onBlur={() =>
                      casenoteClickEventTracking(
                        caseNoteQuestionsData[
                          CaseNoteQuestionTypes.FollowUpPlan
                        ]?.id?.toString(),
                        EventCategories.questionId,
                      )
                    }
                  />
                </section>
              )}
            />
          </section>
          <section className="additional-recommendation-wrapper">
            <section className="header-info-container extra-padding">
              <label
                className="form-label"
                htmlFor="additonalSessionRecommended"
              >
                {
                  caseNoteQuestionsData[
                    CaseNoteQuestionTypes.AdditonalSessionRecommended
                  ]?.question
                }
              </label>
              <Tags
                variant="disclaimer"
                tags={t('SHARED_DISCLAIMER')}
                icon={alertCircleIcon}
                uniqueKey="casenote_disclaimer"
                toolTipText={t('DISCLAIMER_TEXT')}
                toolTipClass="customTooltip"
              />
            </section>
            <Controller
              name="additonalSessionRecommended"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <section className="editor-wrapper">
                  <TextEditor
                    {...field}
                    onTextValueChange={(note) =>
                      field.onChange({
                        id: note,
                        questionId:
                          caseNoteQuestionsData[
                            CaseNoteQuestionTypes.AdditonalSessionRecommended
                          ]?.id,
                      })
                    }
                    defaultTemplate={field.value?.id || ''}
                    parametersValue={[]}
                    maxCharacterLimit={3}
                    showMaxCharText={false}
                    placeHolderText={t('ADDITIONAL_RECOMMENDATION_PLACEHOLDER')}
                    className="custom-editor-height"
                    inputType="number"
                    onFocus={initiateQuestionTracking}
                    onBlur={() =>
                      casenoteClickEventTracking(
                        caseNoteQuestionsData[
                          CaseNoteQuestionTypes.AdditonalSessionRecommended
                        ]?.id?.toString(),
                        EventCategories.questionId,
                      )
                    }
                  />
                </section>
              )}
            />
          </section>
          <InformationModal
            headerText={t('IMPORTANT')}
            bodyText={t('WARNING_DESC')}
            onClose={() => setShowWarning(false)}
            show={showWarning}
            rightBtnLabel={t('confirmButton')}
            leftBtnLabel={t('CANCEL_CTA')}
            customBtnContainerStyle="custom-style"
            borderRadius="100px"
            leftBtnVariant="secondary"
            fontSize={14}
            fontWeight="600"
            width="221px"
            height="45px"
            headerFontSize={24}
            contentFontSize={16}
            leftBtnClick={() => {
              setShowWarning(false);
              casenoteClickEventTracking('cancel');
            }}
            rightBtnClick={() => {
              navigate(-1);
              casenoteClickEventTracking('reminder');
            }}
          />
          <motion.input
            whileHover={{ scale: 1.025 }}
            whileTap={{ scale: 0.975 }}
            className="submit-button"
            value={t('SAVE_CTA')}
            type="submit"
          />
        </form>
      </section>
    </article>
  );
}

export default CaseNote;
