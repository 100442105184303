import * as React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { twMerge } from 'tailwind-merge';
import { Button } from '../../../components/Button';
import UnOrderedList, {
  UnorderedListProps,
} from '../../../components/UnOrderedList';
import { getDisplayDate } from '../../../../../utilities/common/Date';
import { ClientMatchedOnDateFormat } from '../../../../utilities/common/Date';
import { getCurrentLocale } from '../../../../../i18n';
import { Tag } from '../../../components/Tag';
import { ReactComponent as ChevronRightIcon } from '../../../../assets/rightChevron.svg';
import { variants } from '../../../../shared/constant/Colors';

interface IQuestionnaireCardProps {
  className?: string;
  isPIQ?: boolean;
  isQuestionnaireCompleted?: boolean;
  onClick: () => void;
  title: string;
  mainImage: string;
  assignmentStatus: string;
  assignmentStatusVariant: string;
  createdAt: string;
  isDisabled?: boolean;
}

export default function QuestionnaireCard({
  className,
  isPIQ = false,
  isQuestionnaireCompleted,
  onClick,
  title,
  mainImage,
  assignmentStatus,
  assignmentStatusVariant,
  createdAt,
  isDisabled,
}: Readonly<IQuestionnaireCardProps>) {
  const { t } = useTranslation();
  const currentLocale = getCurrentLocale();

  const QuestionaireInfo: UnorderedListProps['items'] = React.useMemo(() => {
    const items = [
      {
        label: t('ASSIGNED_ON', {
          date: getDisplayDate({
            locale: currentLocale,
            translatorResource: { ja: 'DISPLAY_DATE_WITHOUT_MENTION' },
            format: { ja: 'MM,DD,YYYY,dddd', en: ClientMatchedOnDateFormat },
            selectedDate: moment(createdAt),
            translate: t,
          }),
        }),
      },
      {
        label: (
          <Tag variant={assignmentStatusVariant as keyof typeof variants}>
            {assignmentStatus}
          </Tag>
        ),
      },
    ];
    return items;
  }, [currentLocale]);
  const ctaLabel = isQuestionnaireCompleted ? 'VIEW_RESULTS' : 'remindCTA';

  return (
    <section
      className={twMerge(
        `w-full bg-white p-5 md:p-2.5 box-border flex items-center justify-between border border-solid border-gray-200 rounded-lg ${
          isPIQ
            ? 'shadow-[0px_4px_12px_0px_rgba(16,24,40,0.14)] mb-3'
            : 'shadow-sm'
        }`,
        className,
      )}
    >
      <section className="w-[75%] flex justify-between">
        <section className="w-full flex gap-2 items-center">
          <img
            src={mainImage}
            className="w-[73px] h-[67px] rounded-lg"
            alt="questionnaire-illustration"
          />
          <section className="w-[70%] flex flex-col gap-1.5">
            <section className="text-sm font-semibold text-gray-900 overflow-hidden whitespace-nowrap text-ellipsis">
              {title}
            </section>{' '}
            {!isPIQ && (
              <UnOrderedList
                className="flex flex-wrap items-center"
                items={QuestionaireInfo}
                liClassName="min-w-0 [&:nth-child(1)]:max-w-[50%]"
              />
            )}
          </section>
        </section>
      </section>
      <section
        className={`min-w-[25%] flex justify-${isPIQ ? 'end' : 'center'}`}
      >
        <Button
          disabled={isDisabled}
          variant={`${isQuestionnaireCompleted ? 'ghost' : 'secondary'}`}
          className={`${isPIQ ? '' : 'min-h-[32px] min-w-fit w-[156px]'} ${
            isQuestionnaireCompleted && !isPIQ
              ? 'border border-primary-100 text-primary-600 bg-primary-50'
              : ''
          }`}
          onClick={onClick}
        >
          {isPIQ ? (
            <ChevronRightIcon className="text-gray-400" />
          ) : (
            t(ctaLabel)
          )}
        </Button>
      </section>
    </section>
  );
}
