export enum ProviderRole {
  COACHING = '0',
  CLINICAL = '1',
  HELPLINE = '2',
  CARENAVIGATOR = '4',
  NUTRITIONIST = '5',
  FITNESS = '6',
  FINANCIAL = '7',
  EXECUTIVE = '8',
  LEGAL = '9',
  CAREOFFSITE = '10',
  ONSITE = '12',
}

// Not in use currently, in future it will be used
// that's why keeping separately here from above enum
export const ProviderCoachingOffsiteRole = '11';

export const dayOfWeekNumbers = ['0', '1', '2', '3', '4', '5', '6', '7'];

export enum InteractionType {
  Skipped = '0',
  Completed = '1',
}
