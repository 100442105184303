import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { useFetch, useMutation } from '../../../../../utilities/hooks/useFetch';
import {
  getClientAssignments,
  sendAssignmentReminder,
} from '../../../../shared/service/API/client';
import { IClientAssignment } from '../types/client';
import {
  getAssignmentResponses,
  getPIQReports,
} from '../../../../shared/service/API/assignments';
import { ClientInsightsReport } from '../../../../../shared/types/response/assignment';
import { IQuestionnaireDetailsResponse } from '../types/assignment';

export function useClientAssignments(clientId: string, type: string) {
  return useFetch<IClientAssignment[]>(
    ['client-details-v2-assignments', clientId, type],
    () => getClientAssignments(clientId, type),
    {
      enabled: Boolean(clientId),
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    },
  );
}

export function useSendAssignmentReminder(
  handleSuccess: (response: string, assignmentId: string) => void,
  clientId?: string,
) {
  const { t } = useTranslation();
  return useMutation(
    (assignmentId: string) =>
      clientId ? sendAssignmentReminder(clientId, assignmentId) : undefined,
    {
      onSuccess: (response: string, assignmentId: string) => {
        handleSuccess(response, assignmentId);
      },
      onError: () => {
        toast.error(t('ASSIGNMENT_BE_ERROR'));
      },
    },
  );
}

export function useGetAssignmentResponse(
  handleSuccess: (response: IQuestionnaireDetailsResponse) => void,
) {
  return useMutation(
    ({ clientId, assignmentId }: { clientId: string; assignmentId: string }) =>
      getAssignmentResponses(clientId, assignmentId),
    {
      onSuccess: (response: IQuestionnaireDetailsResponse) => {
        handleSuccess(response);
      },
      onError: () => {
        toast.error('Questionnaire responses not found');
      },
    },
  );
}

export function usePIQReports(clientId: string) {
  return useFetch<ClientInsightsReport>(
    ['piq-reports', clientId],
    () => getPIQReports(clientId),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    },
  );
}
